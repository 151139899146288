

export default class Sorting {
    private _startCallback: any;
    private _endCallback: any;
    private _active = false

    constructor(startCallback: any, endCallback: any) {
        this._startCallback = startCallback;
        this._endCallback = endCallback
    }

    get active() {
        return this._active
    }

    clone() {

        jQuery("#kind2_bulk_add_original_items li").each(function(){
            // clone the original items to make their
            // absolute-positioned counterparts...
            var item = jQuery(this);
            var item_clone = item.clone();
            // 'store' the clone for later use...
            item.data("clone", item_clone);

            // set the initial position of the clone
            var position = item.position();
            item_clone.css("left", position.left);
            item_clone.css("top", position.top);

            // append the clone...
            jQuery("#kind2_bulk_add_cloned_items").append(item_clone);
        });
    }

    init() {

        jQuery("#kind2_bulk_add_original_items").sortable({
            axis: "x",
            // on sorting start, hide the original items...
            // only adjust the visibility, we still need
            // their float positions..!
            start: (e, ui)=> {
                // loop through the items, except the one we're
                // currently dragging, and hide it...
                ui.helper.addClass("exclude-me");
                jQuery("#kind2_bulk_add_original_items li:not(.exclude-me)")
                    .css("visibility", "hidden");

                // get the clone that's under it and hide it...
                ui.helper.data("clone").hide();
                if (this._startCallback) {
                    this._startCallback()
                }
            },

            stop: (e, ui) =>{
                // get the item we were just dragging, and
                // its clone, and adjust accordingly...
                jQuery("#kind2_bulk_add_original_items li.exclude-me").each(function(){
                    var item = jQuery(this);
                    var clone = item.data("clone");
                    var position = item.position();

                    // move the clone under the item we've just dropped...
                    clone.css("left", position.left);
                    clone.css("top", position.top);
                    clone.show();

                    // remove unnecessary class...
                    item.removeClass("exclude-me");
                });

                // make sure all our original items are visible again...
                jQuery("#kind2_bulk_add_original_items li").css("visibility", "visible");

                if (this._endCallback) {
                    this._endCallback()
                }
            },

            // here's where the magic happens...
            change: function(e, ui){
                // get all invisible items that are also not placeholders
                // and process them when ordering changes...
                jQuery("#kind2_bulk_add_original_items li:not(.exclude-me, .ui-sortable-placeholder)").each(function(){
                    var item = jQuery(this);
                    var clone = item.data("clone");

                    // stop current clone animations...
                    clone.stop(true, false);

                    // get the invisible item, which has snapped to a new
                    // location, get its position, and animate the visible
                    // clone to it...
                    var position = item.position();
                    clone.animate({left: position.left}, 500);
                });
            }
        });
    }

    setup() {
        if( jQuery("#kind2_bulk_add_original_items li").length === 0) {
            setTimeout(() => {
                this.setup()
            }, 10);
            return;
        }
        setTimeout(() => {
            this.init();
        }, 10)
        this.clone();
        this._active = true;
    }
}